import React from "react";
import careers from "../data/careers.json";
const JobTitle = () => {
  return (
    <div className="jobs">
      <div className="accordion-list">
        <ul>
          {careers.careers.map((eachCareer, i) => (
            <li>
              <a
                data-toggle="collapse"
                className="collapse"
                href={`#list-${i + 1}`}
              >
                <span>{eachCareer.title}</span>{" "}
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div
                id={`list-${i + 1}`}
                className="collapse show"
                data-parent=".accordion-list"
              >
                <div className="read-more">
                  {eachCareer.description} ...
                  <span data-toggle="collapse" href={`#content-${i + 1}`}>
                    Read More
                  </span>
                  <div
                    id={`content-${i + 1}`}
                    className="collapse"
                    data-parent={`#list-${i + 1}`}
                  >
                    <div className="heading">
                      <h4>Key Responsibilities</h4>
                      <div class="text-center">
                        <button type="submit">Apply Now</button>
                      </div>
                    </div>
                    <ul>
                      {eachCareer.responsibilities.map((eachResponsibility) => (
                        <li>{eachResponsibility}</li>
                      ))}
                    </ul>
                    <h4>Requirements</h4>
                    <ul>
                      {eachCareer.requirements.map((eachRequirement) => (
                        <li>{eachRequirement}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default JobTitle;
