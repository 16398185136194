import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import JobTitle from "../components/jobs";
import '../../static/assets/css/style.css'

const Careers = () => {
  return (
    <div>
    <Header/>
    <section id="careers" class="careers">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Careers</h2>
        </div>
        <JobTitle />
      </div>
    </section>
    <Footer/>
    </div>
  );
};

export default Careers;
